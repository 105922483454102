@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
    font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #050916;
  width: 100%;
}

.backgroundColors {
  background-image: url("./assets/BackgroundColorsShort.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Hero-bg {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin-bottom: 100px;
}

.video-bg {
  position: absolute;
  top: 42%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: -50;
}

@media (max-width: 1024px) {
  .video-bg {
    min-width: 200%;
    min-height: 200%;
  }
      }

      @media (max-width: 764px) {
        .video-bg {
          top: 40%;
          min-width: 100%;
          min-height: 100%;
        }
            }
      
.video-desktop {
  display: none;
}

.video-mobile {
  display: block;
}

@media (min-width: 768px) {
  .video-desktop {
    display: block;
  }
  
  .video-mobile {
    display: none;
  }
}


.Overlay-bg {
  background: rgb(5,9,22);
  background: linear-gradient(270deg, rgba(5,9,22,1) 10%, rgba(5,9,22,0) 80%);
  position: fixed;
  top: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 30%;
  height: 100vh;
  z-index: 20;
}


section {
  margin-bottom: 100px;
  }

  @media (max-width: 768px) {
    section {
      margin-bottom: 20px;
      }
      }
.fadeKol {  animation: fade-in linear; animation-timeline: view() } @keyframes fade-in { 0% { scale: 0.9; opacity:0 } 40% { scale: 1; opacity: 1; } }


.WorldApp {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.world-map-container {
  position: relative;
  width: 1000px; /* Adjust based on your SVG dimensions */
  height: 500px; /* Adjust based on your SVG dimensions */
  margin: auto;
}

.world-map-svg {
  width: 100%;
  height: 100%;
}

.dots-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Ensure only the dots are clickable */
  z-index: 100;
}

.dot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: blue;
  border-radius: 50%;
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff10;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(165, 165, 213);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: blue;
}

/* iPhone 11 Pro, iPhone 12 Pro, iPhone 13 Pro (5.8 inch) */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) {
  }

/* iPhone 11, iPhone 12, iPhone 13 (6.1 inch) */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 896px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    }

/* iPhone 11 Pro Max, iPhone 12 Pro Max, iPhone 13 Pro Max (6.5 inch) */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 896px) 
  and (-webkit-min-device-pixel-ratio: 3) {
    }

/* iPad (7th, 8th, 9th generation), iPad Air (3rd, 4th generation), iPad Pro 10.5" */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  /* Your styles here */
}

/* iPad Pro 11" (1st, 2nd, 3rd generation) */
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 1194px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  /* Your styles here */
}

/* iPad Pro 12.9" (1st, 2nd, 3rd, 4th, 5th generation) */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  /* Your styles here */
}

/* MacBook Air, MacBook Pro 13" */
@media only screen 
  and (min-device-width: 1280px) 
  and (max-device-width: 1440px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  .hero-quote-block-left,
  .hero-quote-block-right {
    top: 18vh;
  }
  .hero-author-left-text,
  .hero-author-right-text {
    top: 25vh;
  }
  .hero-socials-container {
    top: 42vh;
  }
}

/* MacBook Pro 15" */
@media only screen 
  and (min-device-width: 1440px) 
  and (max-device-width: 1680px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  .hero-quote-block-left,
  .hero-quote-block-right {
    top: 20vh;
  }
  .hero-author-left-text,
  .hero-author-right-text {
    top: 27vh;
  }
  .hero-socials-container {
    top: 44vh;
  }
}

/* MacBook Pro 16" */
@media only screen 
  and (min-device-width: 1536px) 
  and (max-device-width: 1920px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  .hero-quote-block-left,
  .hero-quote-block-right {
    top: 28vh;
  }
  .hero-author-left-text,
  .hero-author-right-text {
    top: 31vh;
  }
  .hero-socials-container {
    top: 46vh;
  }
}

/* iMac 21.5" */
@media only screen 
  and (min-device-width: 1920px) 
  and (max-device-width: 4096px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  .hero-quote-block-left,
  .hero-quote-block-right {
    top: 24vh;
  }
  .hero-author-left-text,
  .hero-author-right-text {
    top: 31vh;
  }
  .hero-socials-container {
    top: 48vh;
  }
}

/* iMac 27" and iMac Pro 27" */
@media only screen 
  and (min-device-width: 2560px) 
  and (max-device-width: 5120px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  .hero-quote-block-left,
  .hero-quote-block-right {
    top: 26vh;
  }
  .hero-author-left-text,
  .hero-author-right-text {
    top: 33vh;
  }
  .hero-socials-container {
    top: 50vh;
  }
}

/* Portfolio Slider Button Styles */
.slick-prev,
.slick-next {
  position: absolute;
  top: 25% !important; /* Align with logo */
  transform: translateY(-50%);
  z-index: 10;
  width: 40px !important;
  height: 40px !important;
}

.slick-prev {
  left: -50px !important; /* Move outside the cards */
}

.slick-next {
  right: -50px !important; /* Move outside the cards */
}

/* Team Member Card Styles */
.team-member-card {
  height: 500px; /* Fixed height */
  position: relative;
  overflow: hidden;
}

.team-member-bio {
  max-height: 200px; /* Adjust based on your design */
  overflow-y: auto;
  padding-right: 10px;
}

.team-member-container {
  min-height: 600px;
  max-height: 600px;
}

.team-member-container::-webkit-scrollbar {
  width: 6px;
}

.team-member-container::-webkit-scrollbar-track {
  background: transparent;
}

.team-member-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.team-member-container::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

.team-member-container {
  min-height: 700px; /* Ensure consistent section height */
  margin-bottom: 50px; /* Space for contact section */
}